.header {
  background-color: rgb(205 214 215);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100vw;
 
  
  &__titulo {
    font-size: var(--font-size-titulo);
    font-weight: 500;
    text-align: center;
  }

  &__btn_voltar {
    position: absolute;
  }

  &__btn_mobile{
    display: none;
  }
  
  &__btn_desktop{
    display: block;
  }

}

.outlet {
  width: 100vw;
  min-height: 80vh;
}

.container{
  display: block;
  position: fixed;
}


@media screen and (max-width:1023px) {
  .container{
    position: static;
  } 

  .header{
    padding: 0;
    margin: 0 0.3rem;
  }

  .header__btn_voltar {
    top: 0;
  }

  .header__btn_mobile{
    display: block;
    margin: 0.3rem;
  }
  
  .header__btn_desktop{
    display: none;
  }

}