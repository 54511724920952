.headerModal{
    word-break: break-word;
}

.bodyModal{
    word-break: break-word;
    & p {
        font-size: var(--font-size-destaque);
        padding: .3rem;
    }

    & label {
        font-size: var(--font-size-subtitulo);
        font-weight: 500;
    }
}

.botaoLink{
    width: 100%;
    & a {
        display: block;
        height: 100%;
    }
}

@media screen and (max-width: 1023px) {

    .botaoLink{
        width: fit-content;
    }

    .descricao_wf{
        overflow-y: auto;
        max-height: 43vh;
    }

}