.step {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding-bottom: 0.3rem;
  border-radius: 10px;
  height: inherit;

  &__cards {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: 92%;
    align-content: start;
  }

  &__cabecalho {
    font-size: var(--font-size-subtitulo);
    font-weight: 600;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0.35rem;
    text-align: center;
    color: white;

    position: sticky;
    top: 0;
  }

  &__cabecalho_todo {
    background-color: var(--todo-cabecalho);
  }
  &__conteudo_todo {
    background-color: var(--todo-conteudo);
  }
  &__cabecalho_doing {
    background-color: var(--doing-cabecalho);
  }
  &__conteudo_doing {
    background-color: var(--doing-conteudo);
  }
  &__cabecalho_done {
    background-color: var(--done-cabecalho);
  }
  &__conteudo_done {
    background-color: var(--done-conteudo);
  }
  &__cabecalho_approved {
    background-color: var(--approved-cabecalho);
  }
  &__conteudo_approved {
    background-color: var(--approved-conteudo);
  }

  @media screen and (max-width: 1023px) {
    & {
      display: flex;
      padding-bottom: 0;
      height: 50vh;
    }

    &__cabecalho {
      writing-mode: vertical-rl;
      text-orientation: upright;

      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 0;
    }

    &__cards {
      height: initial;
    }
  }
}
