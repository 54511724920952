.main {
  display: flex;
  justify-content: start;
  gap: 0.32rem;
  flex-wrap: wrap;
  margin: 1rem;
  height: calc(100vh - 125px);

  @media screen and (max-width: 1023px) {
     &{
      margin: 0.3rem;
      height: inherit;
      justify-content: start;
      flex-direction: column;
      flex-wrap: nowrap;
    } 
    
  }
}
