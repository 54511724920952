.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
  }
  
  .modal_wrapper {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }
  
  .modal {
    z-index: 100;
    background: white;
    position: relative;
    margin:20px auto;
    border-radius: 3px;
    max-width: 800px;
    padding: 8px;
    border-radius: 4px;
  }
  
  .modal_header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 8px 0;
    font-size: var(--font-size-subtitulo);
    font-weight: 600;
  }
  
  .modal_button_close {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: var(--font-size-subtitulo);
  }
  
  .modal_body {
    padding: 8px 0;
    font-size: var(--font-size-destaque);
    font-weight: 500;
  }
  
  .modal_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 8px 0;  
    font-size: var(--font-size-subtitulo);
    font-weight: 600;
    gap: 10rem;
    margin: 0 5rem;
  }

  @media screen and (max-width: 1023px) {
    .modal{
      width: 90vw;
   } 
   .modal_footer {
    gap: 0;
    margin: 0;
  }
   
 }

